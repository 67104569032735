import { Platform, Alert } from "react-native";
import Constants from "expo-constants";
import { User } from "../types/user";
import * as ImagePicker from "expo-image-picker";
import * as Notifications from "expo-notifications";
import * as Analytics from "expo-firebase-analytics";
import * as local_storage_pushNeverAsk from "../local_storage/pushNeverAsk";
import * as db_user from "../firebase/Users";
import * as Sentry from "sentry-expo";
import { Timestamp } from "firebase/firestore";
import { ShowToast } from "../lib";
import { Answer, QA } from "../types/question";
import { TwitterToken } from "../types/twitterToken";
import { ScaledSize, useWindowDimensions } from "react-native";
import {
  BackOffCountToAskForReview,
  GetAnswerCountOnDevice,
  GetCountToAskForReview,
  IncrementAnswerCountOnDevice,
} from "../local_storage/answerCountOnDevice";
import * as Linking from "expo-linking";
import * as EmailValidator from "email-validator";
import {
  PostCaptureFund,
  PostTweetOnCall,
  SubmitEmail,
} from "../firebase/Functions";
import { OnCallResponse } from "../types/onCallResponse";
import {
  AnswerQuestion,
  MoveQuestionFromTrash,
  TrashQuestionAnswered,
  UpdateAnswer,
} from "../firebase/Questions";
import * as storageAnswerDraft from "../local_storage/answerDraft";
import { AfterTweetUpdate } from "../types/afterTweetUpdate";
import * as Haptics from "expo-haptics";
import {
  AnswerSubmissionVals,
  AnswerSubmissionFuncs,
} from "../types/answerSubmission";
import { StripeSettingType } from "../types/stripeSettingType";
import { GenerateTweetContent } from "../libShared";
import { GetWillShowApiPostRateLimitHitDialog } from "../local_storage/willShowApiPostRateLimitHitDialog";

const getCameraRollPermission = async () => {
  if (Constants.platform!.ios) {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert(
        "カメラロールへのアクセス許可を有効化されていません。設定を変更してからお試しください。"
      );
      return false;
    }
  }
  return true;
};

export const PickImage = async () => {
  const isGranted = await getCameraRollPermission();
  if (isGranted === false) {
    return undefined;
  }
  return await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [1, 1],
  }).then((result) => {
    if (result.canceled) {
      return undefined;
    } else {
      return result.assets[0].uri;
    }
  });
};

export const GenerateUserPath = async (user: User) => {
  const userFromDb = await db_user.GetUserActive(user.id);
  if (userFromDb === undefined) {
    return user.id;
  }
  const screenNameFromDb = userFromDb.screenName;
  if (screenNameFromDb === undefined) {
    return user.id;
  } else {
    return screenNameFromDb;
  }
};

export const RegisterPushToken = async (user: User) => {
  const pushToken = await RegisterForPushNotificationsAsync();
  if (pushToken && user.pushToken !== pushToken) {
    await db_user.UpdateUser(user.id!, {
      pushToken: pushToken,
      pushAllowed: true,
    });
    await local_storage_pushNeverAsk.SetPushNeverAsk(true);
    Analytics.logEvent("pushPermittediIOS");
  }
};

export const OnPressShare = async (user: User) => {
  const askPushPrelim = async () => {
    return new Promise<boolean>((resolve, reject) => {
      Alert.alert(
        "共有する前に",
        "受け取った質問を見逃さないように、プッシュ通知を許可してください。",
        [
          {
            text: "OK",
            onPress: async () => {
              resolve(true);
            },
            style: "default",
          },
          {
            text: "許可しない",
            onPress: () => {
              local_storage_pushNeverAsk
                .SetPushNeverAsk(true)
                .then(() => resolve(false));
            },
            style: "destructive",
          },
        ]
      );
    });
  };

  if (user.pushToken === undefined && Platform.OS !== "web") {
    const neverAsk = await local_storage_pushNeverAsk.GetPushNeverAsk();
    if (!neverAsk) {
      const wantsNotif = await askPushPrelim().catch((e) => {
        alert(e);
      });
      if (wantsNotif) {
        await RegisterPushToken(user);
      }
    }
  }
  await ShareLinkOnTwitter(user, "Querie.meで質問を募集しています！").catch(
    (e) => {
      alert(e);
    }
  );
  db_user.UpdateUser(user?.id!, {
    lastAnswered: Timestamp.now(),
  });
  Analytics.logEvent("shared");
};

export const ShareLinkOnTwitter = async (user: User, text: string) => {
  const link =
    `https://twitter.com/intent/tweet` +
    `?text=${text}` +
    `&url=https://${
      Constants.manifest?.extra!.env === "prod" ? "" : "dev."
    }querie.me/user/${await GenerateUserPath(user)}`;
  if (Platform.OS === "web") {
    const result = window.open(link, "_blank");
    if (result === null) {
      await Linking.openURL(link);
    }
  } else {
    await Linking.openURL(link);
  }
};

export const RegisterForPushNotificationsAsync = async () => {
  let token = null;
  if (Constants.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return null;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }
  return token as User["pushToken"];
};

export const ToQueryString = (params: any) => {
  return (
    "?" +
    Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            value as string | number | boolean
          )}`
      )
      .join("&")
  );
};

export const AlertAndReportError = (
  e: any,
  location: string,
  noToast?: boolean
) => {
  if (Platform.OS === "web") {
    return;
  }
  const message = `${location} failed. ${e}`;
  if (noToast !== true) {
    ShowToast("error", message);
  }
  Sentry.Native.captureException(message);
  Analytics.logEvent("error", {
    location: location.slice(0, 40),
    error: e.toString().slice(0, 100),
  });
};

interface TweetParams {
  qa: QA;
  user: User;
  twitterToken: TwitterToken;
}

// must return a valid tailwind width fraction (except for 1/1)
export const GetProperWidth = (
  window: ScaledSize
): { num: number; denom: number } => {
  if (window.width >= 1280) {
    return { num: 1, denom: 2 };
  } else if (window.width >= 1024) {
    return { num: 3, denom: 4 };
  } else if (window.width >= 768) {
    return { num: 10, denom: 12 };
  } else {
    return { num: 1, denom: 1 };
  }
};

export const GetScreenSize = (
  window: ScaledSize
): "xl" | "lg" | "md" | "sm" => {
  if (window.width >= 1280) {
    return "xl";
  } else if (window.width >= 1024) {
    return "lg";
  } else if (window.width >= 768) {
    return "md";
  } else {
    return "sm";
  }
};

export const IsPCView = () => {
  const window = useWindowDimensions();
  return window.width >= 1280;
};

export const HitThresholdForReviewRequest = async () => {
  await IncrementAnswerCountOnDevice();
  const answerCount = await GetAnswerCountOnDevice();
  const threshold = await GetCountToAskForReview();
  return answerCount === threshold;
};

export const AskForReview = async () => {
  const askIfSatisfied = async () => {
    return new Promise<boolean>((resolve, reject) => {
      Alert.alert("ご様子のお伺い", "このアプリにご満足頂けていますか?", [
        {
          text: "はい",
          onPress: () => {
            return resolve(true);
          },
        },
        {
          text: "いいえ",
          onPress: () => {
            return resolve(false);
          },
        },
      ]);
    });
  };

  const askForFeedback = async () => {
    return new Promise<boolean>((resolve, reject) => {
      Alert.alert(
        "ご意見を頂けますか？",
        "ご満足いただけるアプリにしていくためにご不満な点やご要望をお聞かせ願えますか？",
        [
          {
            text: "TwitterDMで意見を送る",
            onPress: () => {
              return resolve(true);
            },
          },
          {
            text: "いいえ",
            onPress: () => {
              return resolve(false);
            },
          },
        ]
      );
    });
  };

  const askForReview = async () => {
    return new Promise<boolean>((resolve, reject) => {
      Alert.alert(
        "アプリレビューのお願い",
        "今後も本アプリを無料で提供できるよう、ぜひ星5つの評価をお願いします!!",
        [
          {
            text: "評価する",
            onPress: () => {
              return resolve(true);
            },
          },
          {
            text: "今は評価しない",
            onPress: () => {
              return resolve(false);
            },
          },
        ]
      );
    });
  };

  const isSatisfied = await askIfSatisfied();
  if (isSatisfied) {
    const willReview = await askForReview();
    if (willReview) {
      if (Platform.OS === "ios") {
        Linking.openURL(
          `itms-apps://itunes.apple.com/app/viewContentsUserReviews/id1594141650?action=write-review`
        );
      } else {
        Linking.openURL(
          `market://details?id=com.nakameguro_technologies.querie&showAllReviews=true`
        );
      }
      // StoreReview.requestReview();
    } else {
      BackOffCountToAskForReview();
    }
  } else {
    const willFeedBack = await askForFeedback();
    if (willFeedBack) {
      Linking.openURL(
        "twitter://messages/compose?recipient_id=1440949863097585665"
      );
    }
    BackOffCountToAskForReview();
  }
};

export const HTMLClassStyles = {
  text: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "游明朝",
    textIndent: "0pt",
    verticalAlign: "baseline",
  },
  bullet1: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "游明朝",
    marginLeft: "10pt",
    textIndent: "-10pt",
    verticalAlign: "baseline",
  },
  bullet2: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "游明朝",
    marginLeft: "20pt",
    textIndent: "-20pt",
    verticalAlign: "baseline",
  },
};

export const OnSubmitEmail = async (
  user: User | undefined,
  mailAddress: string,
  stripeSettingType: StripeSettingType
) => {
  if (user === undefined || user.id === undefined) {
    return;
  }
  if (mailAddress === "") {
    await AlertPromisified(
      "メールアドレスが指定されていません。メールアドレスを入力して再度お試しください。"
    );
    return;
  }
  if (EmailValidator.validate(mailAddress) === false) {
    await AlertPromisified(
      "メールアドレスが正しくありません。確認/修正して再度お試しください。"
    );
    return;
  }
  const response = await SubmitEmail(mailAddress, stripeSettingType).catch(
    (e) => {
      alert(e);
      return null;
    }
  );
  if (response === null) {
    await AlertPromisified("許可されていない操作です。");
    return;
  }
  if (response.status === "success") {
    await AlertPromisified(
      "メールアドレス確認用メールを送信しました。24時間以内にこの端末でメールを開き、記載されたリンクを表示してください。"
    );
  } else {
    await AlertPromisified(
      "この端末では登録できません。(iOS/Androidアプリ、ウェブサイトからの登録をお願いします。)"
    );
  }
  return;
};

export const AlertPromisified = async (text: string) =>
  new Promise((resolve) => {
    if (Platform.OS === "web") {
      alert(text);
      resolve("yes");
    } else {
      Alert.alert("お知らせ", text, [
        {
          text: "OK",
          onPress: () => {
            return resolve(true);
          },
        },
      ]);
    }
  });

export const CreateDmAlert = (canOpenUrl: boolean) => {
  alert(
    "お問い合わせはTwitterの公式アカウント(@querie_me_app)へのダイレクトメッセージにてお願いします。"
  );
  canOpenUrl &&
    Linking.openURL(
      "twitter://messages/compose?recipient_id=1440949863097585665"
    );
};

export const OnPressSubmitAnswer = async (
  answerSubmissionVals: AnswerSubmissionVals,
  answerSubmissionFuncs: AnswerSubmissionFuncs
) => {
  if (answerSubmissionVals.question === undefined) {
    return;
  }
  if (
    answerSubmissionVals.question.tips !== 0 &&
    answerSubmissionVals.question.tipsAlreadyCaptured !== true
  ) {
    answerSubmissionFuncs.setLoadingPayment(true);
    const response = await PostCaptureFund(answerSubmissionVals.question).catch(
      (e) => {
        const alertText =
          e.code === "functions/unauthenticated"
            ? "本人確認が完了していないため、Tips質問に回答できません。\n(本人確認は通常24時間以内に完了します。)\n\nまだ提出されていない場合、あるいは本人確認資料提出から24時間以上経過している場合は、アカウント設定の「Querie Tips実績」から最新の状況を確認してください。"
            : `決済が実行できませんでした。下記メッセージを添えてサポートにお問い合わせください。${e}`;
        alert(alertText);
        return null;
      }
    );
    if (response === null) {
      answerSubmissionFuncs.setLoadingPayment(false);
      return;
    }
    const responseData: OnCallResponse = response.data;
    if (responseData.response === "expired") {
      answerSubmissionFuncs.setIsAlertDialogShownTwoOptions(true);
    } else {
      answerSubmissionFuncs.setLoading(true);
      await SubmitAnswer(
        {
          ...answerSubmissionVals,
          question: {
            ...answerSubmissionVals.question,
            tipsAlreadyCaptured: true,
          },
        },
        answerSubmissionFuncs
      );
      answerSubmissionFuncs.setLoading(false);
    }
    answerSubmissionFuncs.setLoadingPayment(false);
  } else {
    answerSubmissionFuncs.setLoading(true);
    await SubmitAnswer(answerSubmissionVals, answerSubmissionFuncs);
    answerSubmissionFuncs.setLoading(false);
  }
};

const tweetErrorText = (e: any) =>
  `エラーにより回答できませんでした。アプリを再起動してからアカウント設定からログアウトして、再ログインしてからもう一度お試しください。もし解決しない場合は御手数ですが下記エラーを添えて運営までお問い合わせください。${e}`;

export const SubmitAnswer = async (
  {
    question,
    isAnswerAsPrivateChecked,
    isTweetChecked,
    answerText,
    replyToQA,
    isLiked,
    isLimitedOnly,
    answerTextLimitedOnly,
    questionId,
    multiAccountAuth,
    user,
  }: AnswerSubmissionVals,
  {
    navigateOnFailure,
    setIsSharePrivateAnsweredDialog,
    navigateToHome,
    setIsApiPostRateLimitHitDialog,
  }: AnswerSubmissionFuncs
) => {
  const shareOnTwitter =
    question.isAskedAsPrivate !== true &&
    isAnswerAsPrivateChecked !== true &&
    isTweetChecked === true;
  const answer: Answer = {
    answer: answerText,
    tweetId: null,
    rootTweetId: replyToQA === undefined ? null : replyToQA.rootTweetId,
    replyTo: replyToQA !== undefined ? replyToQA.tweetId : null,
    answeredAt: Timestamp.now(),
    shareOnTwitter: shareOnTwitter,
    // isApiUsagePermitted: answerSubmissionVals
    replyToQuestionId: null,
    liked: isLiked,
    isAnsweredAsPrivate:
      question.isAskedAsPrivate === true ? true : isAnswerAsPrivateChecked,
    isLimitedOnly,
    isSharedViaApi: true,
  };
  const answerSubmittedToFSandFuncs = isLimitedOnly
    ? {
        ...answer,
        answerTextLimitedOnly: answerTextLimitedOnly,
      }
    : answer;
  const answered = await AnswerQuestion(
    question,
    isLimitedOnly ? answerSubmittedToFSandFuncs : answer
  ).catch((e) => {
    navigateOnFailure();
    return undefined;
  });
  storageAnswerDraft
    .DeleteAnswerDraft(questionId)
    .catch((e) => AlertAndReportError(e, "DeleteAnswerDraft on AnswerScreen"));
  if (answered === undefined) {
    return;
  }
  Analytics.logEvent("answered", {
    type: "new",
  });
  if (shareOnTwitter === true) {
    if (multiAccountAuth === undefined) {
      alert(tweetErrorText("Twitter token not retrieved."));
      return;
    }
    const result = await PostTweetOnCall({
      qa: {
        ...question,
        ...answerSubmittedToFSandFuncs,
      } as QA,
      multiAccountAuth,
      user: user!,
      replyToQuestionId: replyToQA === undefined ? null : replyToQA.id,
    }).catch(async (e) => {
      await TrashQuestionAnswered(questionId!, false).catch((e) => {
        AlertAndReportError(e, "TrashQuestionAnswered on QA Screen");
      });
      await MoveQuestionFromTrash(questionId, "questions_unanswered").catch(
        (e) => {
          AlertAndReportError(e, "MoveQuestionFromTrash on QA Screen");
        }
      );
      await storageAnswerDraft.SetAnswerDraft(questionId, answer.answer);
      alert(tweetErrorText(e));
      return undefined;
    });

    if (result !== undefined) {
      if (result.code === 429) {
        if (user !== undefined) {
          const willShowApiPostRateLimitHitDialog =
            await GetWillShowApiPostRateLimitHitDialog();
          if (willShowApiPostRateLimitHitDialog) {
            setIsApiPostRateLimitHitDialog(true);
            return;
          }
          await ProcessAnswerViaWebIntent(
            questionId,
            answerText,
            isLimitedOnly,
            user!,
            Timestamp.now().seconds,
            answerTextLimitedOnly,
            replyToQA?.tweetId === null ? undefined : replyToQA?.tweetId
          );
          navigateToHome();

          return;
        }
      } else {
        const tweetId = result.response;
        const updateData: AfterTweetUpdate =
          answer.rootTweetId === null
            ? {
                tweetId: tweetId,
                rootTweetId: tweetId,
              }
            : {
                tweetId: tweetId,
                replyToQuestionId: replyToQA?.id,
              };
        UpdateAnswer(questionId, updateData);
      }
    }
  }
  Platform.OS !== "web" &&
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  const didHitReviewRequestThreshold = await HitThresholdForReviewRequest();
  Platform.OS !== "web" && didHitReviewRequestThreshold && AskForReview();
  if (question.isAskedAsPrivate) {
    setIsSharePrivateAnsweredDialog(true);
    return;
  }
  const message =
    shareOnTwitter === true
      ? "回答をTwitterに投稿します🎉"
      : "質問に回答しました🎉";
  ShowToast("success", message);
  navigateToHome();
};

const generateTweetWebIntent = (
  questionId: string,
  answerText: string,
  isLimitedOnly: boolean,
  answeredAtSec: number,
  user: User,
  in_reply_to?: string | null,
  answerTextLimitedOnly?: string
) => {
  const env = Constants.manifest?.extra!.env as "prod" | "dev";
  const { text, hashtags, url } = GenerateTweetContent(
    questionId,
    answerText,
    isLimitedOnly,
    user,
    env,
    answeredAtSec,
    answerTextLimitedOnly
  );
  const textFull =
    user.hashTagPosted === false ? text + url : text + hashtags + url;
  const query = ToQueryString(
    in_reply_to === undefined || in_reply_to === null
      ? { text: textFull }
      : { text: textFull, in_reply_to }
  );
  return "https://twitter.com/intent/tweet" + query;
};

export const ProcessAnswerViaWebIntent = async (
  questionId: string,
  answerText: string,
  isLimitedOnly: boolean,
  user: User,
  answeredAtSec: number,
  answerTextLimitedOnly?: string,
  replyToTweetId?: string
) => {
  const webIntentLink = generateTweetWebIntent(
    questionId,
    answerText,
    isLimitedOnly,
    answeredAtSec,
    user,
    replyToTweetId,
    answerTextLimitedOnly
  );
  if (Platform.OS === "web") {
    window.open(webIntentLink, "_blank");
  } else {
    await Linking.openURL(webIntentLink);
  }
  const updateDate: Partial<Answer> = {
    isSharedViaApi: false,
  };
  await UpdateAnswer(questionId, updateDate);
};
